<template>
  <div class="">
    <div >
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Sections/DPCMember/Layout/Noauth/Header";
import SideBar from "@/components/Sections/DPCMember/Layout/sideMenu";
export default {
  components: {SideBar, Header},
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>

<style scoped> 
</style>




