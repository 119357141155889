import { render, staticRenderFns } from "./MemberChangePassword.vue?vue&type=template&id=48f400c8&scoped=true&"
import script from "./MemberChangePassword.vue?vue&type=script&lang=js&"
export * from "./MemberChangePassword.vue?vue&type=script&lang=js&"
import style0 from "./MemberChangePassword.vue?vue&type=style&index=0&id=48f400c8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f400c8",
  null
  
)

export default component.exports