<template>
  <div class="register-page">
  
    <!-- CONTENTS -->
    <div class="register-page-container">
      
      <div class="register-page-form">

        <!-- FORM SCROLLING -->
        <div class="form_scorlling_login">

          <!-- HEADER -->
          <div class="sign_header_container">
            <div class="sign_header">
              <!-- BACK BUTTON -->
              <div class="sign_header_back">
                <img src="/svg/backArrow.svg" role="button" @click="$router.go(-1)"><br>
              </div>

              <!-- LOGO -->
              <div class="sign_header_logo">
                <img src="/apaly-logo.png" >
              </div>
            </div>
          </div>

          <!-- FORM -->
          <v-form class="login_form_cutom" ref="form" v-model.trim="valid" @submit.prevent="">

            <div class="login_title">
              <div class="font-24 font-weight-400"> Confirm E-mail </div>
              <div class="font-1 " >Confirm your email address and we'll send you a link to reset your password </div>
            </div>

            <div class="col-12 pl-0 pr-0">
              <label for="" style="color:#333 !important"> Company E-mail</label>
              <v-text-field outlined v-model.trim="email" :rules="emailRules" type="email" required dense @keydown.enter.prevent="send" style="border-radius: 8px;"></v-text-field>
            </div>

            <div class="col-12">
              <v-alert v-if="reasonDialog" dense outlined type="error">
                {{ reasonMessage }}
              </v-alert>
            </div>

            <!-- <div class="col-12 d-flex " style="justify-content:space-between;align-items:center">
              <a @click="$router.push('/login')" class="text_nowarp">Back to Login</a>
              <v-btn :disabled="!valid" depressed color="#0069F3" class="reset-btn" style="color:white" @click="send()">
                Send password reset link
              </v-btn>
            </div> -->

            <div class="dialog_buttons">
              <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft no_border" @click="$router.push('/login')" >
                Back to Login
              </button>
              <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" :disabled="linkSent" @click="send()" >
                Send password reset link
              </button>
            </div>

          </v-form>

        </div>

      </div>

      <!-- FOOTER -->
      <div class="sign_footer_position" style="display: none;">
        <div class="sign_footer">
          <span>Don't have an account?</span>
          <strong class="link-text" @click="$router.push('/register')">
            &nbsp; Register &nbsp;
            <img src="/svg/registerArrow.svg" />
          </strong>
        </div>
      </div>

    </div>

    <!-- DIALOG/MODAL -->
    <v-dialog v-model.trim="requestDialog" width="600" class="success-modal" persistent>
      <v-card>
        <span role="button" align="end" class="pt-2 pr-0">
          <p class="font-weight-bold text-gray pr-3 pt-2" @click="backToLogin()">
            <v-icon>mdi-close</v-icon>
          </p>
        </span>
        <v-card-text class="success-modal pt-5">
          <div class="text-center mb-3">
            <img src="/svg/email_sent.svg" />
          </div>
          <div class="display">
            <span class=" mt-2 font-weight-500 mt-5" style="font-size:24px; color: #333333">
              Check your Email
            </span> <br>
            <span class="font-18 mt-2  mt-5">
              If there is an account associated to this e-mail, you will receive a link to set a new password.
            </span>
          </div>
          
          <div class="text-center text--white mt-10">
            <v-btn color="#0069F3" class="submit-btn" @click="backToLogin()">
              Back to Login
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
var _paq = window._paq || [];
_paq.push(['trackPageView']);
import { mapActions } from "vuex";
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";

export default {
  data() {
    return {
      requestDialog: false,
      reasonDialog: false,
      reasonMessage: "",
      email: "",
      changePassEmail: "",
      password: "",
      valid: false,
      validPass: "",
      show: false,
      error: "",
      padless: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      passRules: [(v) => !!v || "Password is required"],
      passsError: "",
      error: "",
      linkSent: false,
      // rules: [v => v.length <= 5 || 'Max 5 characters'],
    };
  },
  computed: {
    domain() {
      return this.email.split("@").pop();
    },
  },
  created() {
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: 'Member ForgotPassword', // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
  },
  methods: {
    ...mapActions(["loginUser"]),
    eventHandling(event) {
      if (event.keyCode == 13) {
        // this.submit();
        console.log("onEnter", event.keyCode);
        // this.send()
      }
    },
    reason() {
      this.reasonDialog = true;
    },
    send() {
      // this.reasonDialog = true; this.reasonMessage = 'test'; return;
      this.reasonDialog = false;
      this.linkSent = true;
      console.log('sent',this.linkSent)
      api()
              .post(`/member/app/request/password/reset`, { email: this.email })
              .catch((err) => {
                if(err){
                    this.requestDialog = false;
                this.reasonDialog = true;
                this.reasonMessage = `This email is not reachable or does not exist`;
                }
              
              })
              .then((res) => {
                if(res){
                       this.requestDialog = true;
                }
              })
              .finally(() => {
                this.linkSent = false;
          });
      // api()
      //   .get(`https://validate.consislab.net/check/v4/${this.email}`)
      //   .catch((err) => {
      //     this.reasonDialog = true;
      //     this.reasonMessage = `This email is not reachable or does not exist`;
      //   })
      //   .then((res) => {
      //     // console.log('consislab', res)
      //     // this.reasonDialog = true; this.reasonMessage = 'test'; return;
      //     this.requestDialog = false;
      //     if (
      //       res &&
      //       res.data &&
      //       res.data.Response &&
      //       res.data.Response.result == "VALID"
      //     ) 
      //     {
      //       api()
      //         .post(`member/app/request//password/reset`, { email: this.email })
      //         .catch((err) => {
      //           this.requestDialog = false;
      //           this.reasonDialog = true;
      //           this.reasonMessage = `This email is not reachable or does not exist`;
      //         })
      //         .then((res) => {
      //           console.log(res);
      //           this.requestDialog = true;
      //         });
      //     } else {
      //       this.reasonDialog = true;
      //       this.reasonMessage = `This email is not reachable or does not exist`;
      //     }
      //   });
    },
    backToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
  <style scoped lang="scss">
  .submit-btn{
    color: white;
  }
  label{
    color: #333333 !important;
    font-weight: 400; 
  }
  .mobile-footer{
    margin-top: 0px !important;
  }
  .logo-style{
    display: block !important;
  }
   .provider-logo {
  height: 56px;
  width: 80px;
  background-color: white;
  border: 0.5px solid #cccccc;
  border-radius: 4px;
  left: 8px;
  top: 40px;
  background-size: contain;
  background-position: center;
}
.v-input__control{
    margin-bottom: 0px !important;
  }
.register-page {
  // padding: 64px;
}
.apaly-logo {
  width: 130px;
}
.register-page-container {
  margin: 0 auto;
}
.register-page-logo {
  left: 30px;
}
.register-page-title {
  text-align: center;
}
.register-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}
.register-page-error-box {
  padding: 20px 140px;
}
.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.authentication-page-right-side-form {
    padding: 0;
    width: 100%;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text{
    color:#0069F3;
    font-weight: 700;
    font-size: 16px;
    cursor:pointer;
  }

@media only screen and (max-width: 600px) {
  .apaly-logo{
    height: 40px;
    width: 78px;
  }
  .mobile-footer{
    margin-top: 38vh !important;
  }
  .logo-style{
    display: flex !important;
    justify-content: space-between;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .register-page-logo {
    position: inherit;
  }
  .register-page {
    padding: 24px;
  }
  .register-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .register-page-container {
    width: 100%;
  }
  .register-page-footer {
    padding: 0;
    margin-top: 20px;
  }
  .register-page-error-box {
    padding: 10px 0;
  }
  // .authentication-page-right-side-form {
  //   width:100%;
  //   padding: 0;
  // }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
  