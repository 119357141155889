<template>
  <div class="set_password_layout">
    <!-- CONTENT -->
    <div class="register-page-container">
      <div class="register-page-form">
        <!-- FORM SCROLLING -->
        <!-- <div class="form_scorlling_add_family"> -->

        <!-- HEADER -->
        <div class="sign_header_container">
          <div class="sign_header">
            <!-- BACK BUTTON -->
            <div class="sign_header_back">
              <img
                src="/svg/backArrow.svg"
                role="button"
                @click="redirect()"
              /><br />
            </div>

            <!-- LOGO -->
            <div class="sign_header_logo">
              <img src="/apaly-logo.png" />
            </div>
          </div>
        </div>

        <!-- FORM SCROLLING -->
        <div class="form_scorlling_add_family">
          <!-- FORM -->
          <div class="form_desk_up" id="scrollUpForm">
            <v-form
              v-model.trim="validInfo"
              ref="confirmInfo"
              class="form_cutom_width mb-10"
            >
              <div class="font-36 font-weight-400 mb-3">Edit</div>
              <br />
              <div>
                <label>What is your relationship to the employee?</label>
                <v-select
                  class="round-input"
                  @keydown.enter.prevent
                  v-model.trim="subscriber.subscriberRelation"
                  :items="relationships"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="font-24 font-weight-500 mb-2">
                Personal Information
              </div>

              <div>
                <label
                  >First Name
                  <small>(as shown in your insurance card)</small></label
                >
                <v-text-field
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="subscriber.firstName"
                  :rules="requiredRules"
                  placeholder="Enter First Name"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label
                  >Last Name
                  <small>(as shown in your insurance card)</small></label
                >
                <v-text-field
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="subscriber.lastName"
                  :rules="requiredRules"
                  placeholder="Enter Last Name"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Date of Birth</label>
                <v-menu
                  @keydown.enter.prevent
                  ref="menu"
                  hide-details
                  v-model.trim="calendarMenu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                  location="start"
                  :nudge-bottom="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      @keydown.enter.prevent
                      class="round-input"
                      v-model="dateFormatted"
                      @blur="subscriber.dob = parseDate(dateFormatted)"
                      outlined
                      v-mask="'##/##/####'"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      placeholder="Enter Date of Birth: MM/DD/YYYY"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>
              <div>
                <label>Gender</label>
                <v-select
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="subscriber.gender"
                  :items="genders"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="font-24 font-weight-500 mb-2">
                Insurance Information
              </div>
              <div class="font-12 font-weight-400 mb-2">
                As shown on your insurance card
              </div>
              <div>
                <label>Employer</label>
                <v-text-field
                  @keydown.enter.prevent
                  @wheel.prevent
                  class="round-input"
                  v-model.trim="subscriber.companyName"
                  :rules="requiredRules"
                  placeholder="Employer"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>What is your member ID?</label>
                <v-text-field
                  @keydown.enter.prevent
                  @wheel.prevent
                  class="round-input"
                  v-model.trim="subscriber.memberId"
                  :rules="requiredRules"
                  type="number"
                  placeholder="Enter Member ID from their insurance card"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Health Plan</label>
                <v-select
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="subscriber.planId"
                  :items="plans"
                  item-text="name"
                  item-value="planId"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
              </div>

              <div class="dialog_buttons mt-7">
                <!-- MEM 450: Canceling should direct users to Home -->
                <button
                  class="dialog_double_button dialog_btn_second dialog_btn_floatLeft"
                  @click.prevent="redirect()"
                >
                  Cancel
                </button>
                <button
                  class="dialog_double_button dialog_btn_primary dialog_btn_floatRight"
                  :disabled="!validInfo"
                  @click.prevent="editPersonalInformation()"
                >
                  Save Information
                </button>
              </div>
            </v-form>
          </div>
        </div>

        <!-- </div> -->
      </div>
    </div>

    <!-- ALL SET -->
    <v-dialog v-model="allSetModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="redirect()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">Great! You're all set.</div>
          <div class="dialog_subtext dialog_second">
            A representative from the clinic will reach out to schedule your
            first appointment
          </div>
          <div class="dialog_buttons">
            <button
              class="dialog_single_button dialog_btn_primary"
              @click="redirect()"
            >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import moment from "moment";
import { mapState } from "vuex";

export default {
  props: {
    dependentModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      // subscriber: {},
      validInfo: false,
      checkingEligibility: false,
      calendarMenu: false,
      // dateFormatted: "",
      formattedPhoneNumber: "",
      confirmMember: {},
      dependentId: "",
      reviseDependentInfo: false,
      providerId: "",
      locationId: "",
      memberInfo: false,
      memberError: false,
      confirmMemberErrorModal: false,
      confirmMemberSuccessModal: false,
      confirmSemafor: true,
      addMemberSemafor: true,
      confirmMemberSemafor: true,
      allSetModal: false,
      plans: [],
      genders: [
        { text: "Female", value: "f" },
        { text: "Male", value: "m" },
      ],
      relationships: [
        { text: "Self", value: "Self" },
        { text: "Spouse", value: "Spouse" },
        { text: "Dependent", value: "Dependent" },
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
    };
  },
  components: {},

  computed: {
    ...mapState({
      subscriber: (state) => state.memberInfo,
    }),
    dateFormatted() {
      if (this.subscriber && this.subscriber.dob) {
        return moment.utc(this.subscriber.dob).format("MM/DD/YYYY");
      } else {
        return "";
      }
    },
  },
  watch: {
    // "subscriber.dob"(val) {
    //   this.dateFormatted = moment.utc(this.$store.state.memberInfo.dob).format("MM/DD/YYYY");
    // },
  },
  mounted() {},
  created() {
    this.getHomeInformation();
    // this.getaccountInformation();
    this.getPlans();
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Add Dependent", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
  },
  methods: {
    editPersonalInformation() {},
    redirect() {
      let redirect = localStorage.getItem("redirect");
      if (redirect == "/home") {
        this.$router.push("/home");
      } else if (redirect == "/account/details") {
        this.$router.push("/account/details");
      } else {
        console.log("Redirect is empty: ", redirect);
      }
    },
    goHome() {
      this.$router.push("/home");
    },
    goAccountSettings() {
      this.$router.push("/account/details");
    },
    reloadPage() {
      if (window.location.pathname == "/account/details") {
        window.location.reload();
      } else if (window.location.pathname == "/confirm-plan-information") {
        this.$router.push("/account/details");
      } else {
        this.$router.push("/account/details");
      }
    },
    scrollUpForm() {
      document
        .getElementById("scrollUpForm")
        .scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
    },

    getHomeInformation() {
      api()
        .get(`/member/app/home`)
        .then((res) => {
          if (res) {
            this.providerId = res.data.providerId;
            this.locationId = res.data.locationId;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getaccountInformation() {
      api()
        .get(`/member/app/account`)
        .then((res) => {
          if (res) {
            this.user = res.data.personal;
            this.role = res.data.subscriberRelation;
            this.subscriber = res.data.personal;
            this.subscriber.subscriberRelation = res.data.subscriberRelation;
            //this.newMember.memberId = this.user.memberID;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    formatPhoneNumber(event) {
      this.newMember.phone = event;
      this.formattedPhoneNumber = this.formatAsPhoneNumber(
        this.newMember.phone
      );
    },
    confirmFormatPhoneNumber(event) {
      this.confirmMember.phone = event;
      this.confirmFormattedPhoneNumber = this.formatAsPhoneNumber(
        this.confirmMember.phone
      );
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value =
        value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    getPlans() {
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;
          }
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data;
          } else {
            this.error = "Something went wrong. Please try again later.";
          }
        });
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
    
    <style scoped lang="scss">
.register-page-form {
  // border: 1px solid red;
}
.register-page-container {
  width: 100%;
}
.register-page {
  // border: 1px solid red;
  padding: 20px;
  margin-top: 0px;
  height: 100vh;
  // border: 1px solid red;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.hide-desktop {
  display: none !important;
}
.round-input {
  border-radius: 8px;
}
label {
  color: #333333 !important;
  font-weight: 400;
}
.family-page {
  padding: 64px;
  margin-top: 5vh;
}
.apaly-logo {
  width: 130px;
}
.family-page-container {
  margin: 0 auto;
}
.family-page-logo {
  left: 30px;
}
.family-page-title {
  text-align: center;
}
.family-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}

.family-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.family-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.family-page-right-side-form {
  width: 50%;
  align-self: center;
  padding-left: 20px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
@media only screen and (max-width: 600px) {
  .apaly-logo {
    height: 40px;
    width: 70px;
  }
  .hide-desktop {
    display: flex !important;
  }
  .hide-mobile {
    display: none !important;
  }
  .router-view {
    padding-top: 0px !important;
  }
  .family-page-logo {
    display: flex;
    justify-content: space-between;
  }
  .family-page {
    padding: 24px;
    margin-top: 0px;
  }
  .family-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .family-page-container {
    width: 100%;
  }
  .family-page-footer {
    padding: 0;
    margin-top: 20px;
  }

  .family-page-right-side-form {
    width: 100%;
    padding: 0;
  }
  .family-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>
    