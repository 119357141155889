<template>
  <div>
    <v-dialog v-model="dependentModal" width="600">
      <v-card>
        <span class="pa-5 justify-space-between d-flex">
          <span class="font-20 font-weight-500">Add a Dependent</span>
          <v-icon @click="closeMemberModal()">close</v-icon>
        </span>
        <v-card-text class="pt-2">
          <div class="">
            <v-form
              style="width: 100%"
              v-model.trim="validInfo"
              ref="confirmInfo"
            >
              <div>
                <label
                  >First Name
                  <small>(as shown on their insurance card)</small></label
                >
                <v-text-field
                  v-model="newMember.firstName"
                  :rules="requiredRules"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label
                  >Last Name
                  <small>(as shown on their insurance card)</small></label
                >
                <v-text-field
                  v-model="newMember.lastName"
                  :rules="requiredRules"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Date of Birth</label>
                <v-menu
                  ref="menu"
                  hide-details
                  v-model.trim="calendarMenu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                  location="start"
                  :nudge-bottom="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      @blur="newMember.dob = parseDate(dateFormatted)"
                      outlined
                      v-mask="'##/##/####'"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      placeholder="MM/DD/YYYY"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
              </div>
              <div>
                <label>Gender</label>
                <v-select
                  v-model="newMember.gender"
                  :items="genders"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  outlined
                  dense
                ></v-select>
              </div>
              <div>
                <label>Mobile Phone Number</label>
                <v-text-field
                  v-model="formattedPhoneNumber"
                  maxlength="12"
                  @input="formatPhoneNumber"
                  :rules="phoneRules"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Member ID</label>
                <v-text-field
                  v-model="newMember.memberId"
                  :rules="requiredRules"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Health Plan</label>
                <v-select
                  v-model="newMember.planId"
                  :items="plans"
                  item-text="name"
                  item-value="planId"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
              </div>
              <div>
                <label>Relationship to subscriber</label>
                <v-select
                  v-model="newMember.subscriberRelation"
                  :items="relationships"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  outlined
                  dense
                ></v-select>
              </div>
              <v-divider horizontal></v-divider>
              <span align="end" class="text-end justify-end pa-3 d-flex">
                <a
                  @click="closeMemberModal()"
                  color="primary"
                  outlined
                  class="mt-2 mr-5"
                >
                  Cancel</a
                >
                <v-btn
                  @click="addMember()"
                  color="primary"
                  :disabled="!validInfo"
                >
                  Confirm</v-btn
                >
              </span>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="checkingEligibility" width="350px">
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="checkingEligibility = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center mt-3">
          <v-progress-circular
            indeterminate
            :size="50"
            color="primary"
            class="text-center mt-5 mb-5"
          ></v-progress-circular>
          <div class="font-24 font-weight-400 mb-5">
            We are confirming your dependent's insurance information
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="memberErrorModal" width="330px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="reloadPage()" slot="end"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <div
            class="font-24 font-weight-400 mb-3 text-start"
            style="color: #990101"
          >
            Oops. We were not able to confirm your dependent's eligibility.
          </div>
          <div class="font-14 font-weight-400 black-text text-start">
            Please review and update the information to try again or contact
            support at
            <strong>support@apaly.com</strong>
          </div>
          <v-card-actions class="d-flex justify-center align-center pb-5">
            <v-btn @click="reloadPage()" color="#0069F3" outlined>
              Fix later</v-btn
            >
            <v-btn @click="reviseMember()" color="#0069F3" style="color: white">
              Revise Information</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="memberExists" width="330px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="memberExists = false" slot="end"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="justify-center text-start">
          <div class="font-24 font-weight-400 mb-3" style="color: #990101">
            {{ memberErrorMsg }}
          </div>
          <v-card-actions class="d-flex justify-center align-center pb-5">
            <v-btn @click="addAnotherDependent()" color="#0069F3" outlined>
              Add another dependent</v-btn
            >
            <v-btn
              @click="memberExists = false"
              color="#0069F3"
              style="color: white"
              >Okay
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successfullyEnrolledDependent" width="350px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="finishedEnrollment()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-start">
          <div
            class="font-24 font-weight-400 mb-3 text-start"
            style="color: #014d00"
          >
            Dependent Enrolled Successfully
          </div>
          <div
            class="font-16 font-weight-400 text-start"
            style="color: #333333"
          >
            You will soon get follow up outreach directly from your selected
            healthcare organization to schedule your first appointment. We have
            also emailed you their contact information.
          </div>
          <v-card-actions
            class="d-flex justify-space-between align-center pb-5 pt-5 pa-0"
          >
            <v-btn
              @click="finishedEnrollment()"
              color="#0069F3"
              class=""
              outlined
              >Okay</v-btn
            >
            <v-btn
              @click="addDependentAfterEnroll()"
              color="#0069F3"
              class="text-white ml-0"
              style="color: white"
              >Add another dependent</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="memberSuccessModal" width="450px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="cancelOptionsNewMember()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <div
            class="font-24 font-weight-400 mb-3 text-start"
            style="color: #014d00"
          >
            Success! You've added a dependent
          </div>
          <v-card-actions
            class="align-center pb-5 pt-5"
            style="display: flex; flex-direction: column"
          >
            <v-btn
              @click="enrollSameDPC()"
              color="#0069F3"
              style="
                color: white;
                text-transform: unset !important;
                max-width: 100%;
                font-size: 12px;
              "
              class="mb-5"
              width="280"
              >Enroll in the same DPC as main account</v-btn
            >
            <v-btn
              @click="findAnotherProvider()"
              color="#0069F3"
              outlined
              width="280"
              style="
                text-transform: unset !important;
                max-width: 100%;
                font-size: 12px;
              "
              class="ma-0"
            >
              Find another Provider</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="memberSuccessModalNoDPC" width="450px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="cancelOptionsNewMember()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <div
            class="font-24 font-weight-400 mb-3 text-start"
            style="color: #014d00"
          >
            Success! You've added a dependent
          </div>
          <v-card-actions
            class="align-center pb-5 pt-5"
            style="display: flex; flex-direction: column"
          >
            <v-btn
              @click="findAnotherProvider()"
              color="#0069F3"
              style="
                color: white;
                text-transform: unset !important;
                max-width: 100%;
                font-size: 12px;
              "
              class="mb-5"
              width="100%"
              >Select a provider
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim="optionsForDependent" width="450px" persistent>
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="cancelOptions()"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>
        <v-card-text class="justify-center text-center">
          <div
            class="font-24 font-weight-400 mb-3 text-start"
            style="color: #014d00"
          >
            Dependent Plan Information Confirmed
          </div>
          <v-card-actions class="d-block justify-center align-center pb-5 pt-5">
            <v-btn
              @click="enrollSameDPC()"
              color="#0069F3"
              style="
                color: white;
                text-transform: unset !important;
                max-width: 100%;
                font-size: 12px;
              "
              class="mb-5"
              width="280"
            >
              Enroll in the same DPC as main account</v-btn
            >
            <v-btn
              @click="findAnotherProvider()"
              color="#0069F3"
              style="max-width: 100%; font-size: 12px"
              class="ma-0"
              outlined
              width="280"
            >
              Find another Provider</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "@/services/api";
import moment from "moment";
export default {
  props: {
    dependentModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      validInfo: false,
      newMember: {
        dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      checkingEligibility: false,
      memberSuccessModalNoDPC: false,
      confirmMemberPlan: false,
      memberErrorModal: false,
      memberExists: false,
      successfullyEnrolledDependent: false,
      optionsForDependent: false,
      memberErrorMsg: "",
      calendarMenu: false,
      dateFormatted: "",
      formattedPhoneNumber: "",
      confirmMember: {},
      confirmFormattedPhoneNumber: "",
      confirmDateFormatted: "",
      memberSuccessModal: false,
      dependentId: "",
      reviseDependentInfo: false,
      providerId: "",
      locationId: "",
      memberInfo: false,
      memberError: false,
      confirmMemberErrorModal: false,
      confirmMemberSuccessModal: false,
      confirmSemafor: true,
      addMemberSemafor: true,
      confirmMemberSemafor: true,
      plans: [],
      genders: [
        { text: "Female", value: "f" },
        { text: "Male", value: "m" },
      ],
      relationships: [
        { text: "Spouse", value: "Spouse" },
        { text: "Dependent", value: "Dependent" },
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
    };
  },
  components: {},
  watch: {
    "newMember.dob"(val) {
      this.dateFormatted = moment.utc(this.newMember.dob).format("MM/DD/YYYY");
    },
    "confirmMember.dob"(val) {
      this.confirmDateFormatted = moment.utc(this.confirmMember.dob).format(
        "MM/DD/YYYY"
      );
    },
  },
  created() {
    this.getHomeInformation();
    this.getaccountInformation();
    this.getPlans();
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Add Dependent", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
  },
  methods: {
    findAnotherProvider() {
      this.$store.commit("setDependent", this.dependentId);
      this.$router.push("/marketplace");
    },
    cancelNewMember() {
      this.dependentModal = false;
      this.$emit("closeDependentModal");
    },
    closeMemberModal() {
      this.dependentModal = false;
      this.$emit("closeDependentModal");
    },
    addMember() {
      this.checkingEligibility = true;
      // this.addMemberModal = false;
      if (!this.addMemberSemafor) return;
      this.addMemberSemafor = false;
      if (this.reviseDependentInfo) {
        api()
          .post(
            `/member/app/family/member/${this.dependentId}/confirm/info`,
            this.newMember
          )
          .then((res) => {
            if (res) {
              this.checkingEligibility = false;
              // this.addMemberModal = false;
              if (res.data.eligible == false) {
                this.memberErrorModal = true;
              } else {
                if (this.providerId == null && this.locationId == null) {
                  this.memberSuccessModalNoDPC = true;
                } else {
                  if (
                    res.data.pediatric &&
                    res.data.pediatric.covered == false
                  ) {
                    this.memberSuccessModalNoDPC = true;
                  } else {
                    this.memberSuccessModal = true;
                  }
                }
                this.reviseDependentInfo = false;
              }
            }
          })
          .catch((err) => {
            if (err) {
              this.checkingEligibility = false;
              this.$swal(err.response.data, "", "error");
            }
          })
          .finally(() => {
            this.checkingEligibility = false;
            this.addMemberSemafor = true;
          });
      } else {
        api()
          .post(`/member/app/family/member`, this.newMember)
          .then((res) => {
            if (res) {
              // this.addMemberModal = false;
              this.dependentId = res.data.id;
              this.reviseDependentInfo = true;
              api()
                .post(
                  `/member/app/family/member/${res.data.id}/confirm/info`,
                  this.newMember
                )
                .then((res) => {
                  if (res) {
                    this.checkingEligibility = false;
                    // this.addMemberModal = false;
                    if (res.data.eligible == false) {
                      this.memberErrorModal = true;
                    } else {
                      if (this.providerId == null && this.locationId == null) {
                        this.memberSuccessModalNoDPC = true;
                      } else {
                        if (
                          res.data.pediatric &&
                          res.data.pediatric.covered == false
                        ) {
                          this.memberSuccessModalNoDPC = true;
                        } else {
                          this.memberSuccessModal = true;
                        }
                      }
                      this.reviseDependentInfo = false;
                    }
                  }
                })
                .catch((err) => {
                  if (err) {
                    this.checkingEligibility = false;
                    this.$swal(err.response.data, "", "error");
                  }
                })
                .finally(() => {
                  this.checkingEligibility = false;
                  this.addMemberSemafor = true;
                });
            }
          })
          .catch((err) => {
            if (err) {
              this.checkingEligibility = false;
              this.memberExists = true;
              this.memberErrorMsg = err.response.data;
            }
          });
      }
    },
    reloadPage() {
      if (window.location.pathname == "/account/details") {
        window.location.reload();
      } else if (window.location.pathname == "/confirm-plan-information") {
        this.$router.push("/account/details");
      } else {
        this.$router.push("/marketplace");
      }
    },
    addAnotherDependent() {
      this.memberExists = false;
      this.$refs.confirmInfo.reset();
      this.newMember.companyName = this.user.companyName;
      this.newMember.planId = this.user.planID;
      this.newMember.payerId = this.user.payerID;
      this.newMember.groupId = this.user.groupID;
      // this.formatPhoneNumber("");
      this.dependentModal = true;
    },
    cancelOptions() {
      // this.$refs.confirmMemberRef.reset();
      // this.confirmDateFormatted = "";
      // this.confirmFormattedPhoneNumber = "";
      this.dependentId = "";
      this.optionsForDependent = false;
      this.memberSuccessModal = false;
      this.confirmMemberPlan = false;
    },
    cancelOptionsNewMember() {
      this.$refs.confirmInfo.reset();
      this.dateFormatted = "";
      this.formattedPhoneNumber = "";
      this.dependentId = "";
      this.memberSuccessModal = false;
      this.dependentModal = false;
      this.memberSuccessModalNoDPC = false;
      if (window.location.pathname == "/account/details") {
        window.location.reload();
      } else if (window.location.pathname == "/confirm-plan-information") {
        this.$router.push("/account/details");
      } else {
        this.$router.push("/marketplace");
      }
    },
    addDependentAfterEnroll() {
      this.successfullyEnrolledDependent = false;
      this.$refs.confirmInfo.reset();
      // this.newMember = {};
      // this.newMember.dob = ""; // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

      this.newMember.companyName = this.user.companyName;
      this.newMember.planId = this.user.planID;
      this.newMember.payerId = this.user.payerID;
      this.newMember.groupId = this.user.groupID;
      // this.formatPhoneNumber("");
      this.dependentModal = true;
    },

    finishedEnrollment() {
      this.successfullyEnrolledDependent = false;
      this.dependentId = "";
      this.$store.commit("setDependent", "");
      if (window.location.pathname == "/account/details") {
        window.location.reload();
      } else if (window.location.pathname == "/confirm-plan-information") {
        this.$router.push("/account/details");
      } else {
        this.$router.push("/marketplace");
      }
    },
    enrollSameDPC() {
      this.memberSuccessModal = false;
      // this.dependentId = this.$store.getters.getDependentId;
      let data = {
        providerId: this.providerId,
        locationId: this.locationId,
      };
      api()
        .post(
          `/member/app/family/member/${this.dependentId}/set/provider`,
          data
        )
        .catch((err) => {
          if (err) {
            this.$swal(err.response.data, "", "error");
          }
        })
        .then((res) => {
          if (res) {
            this.successfullyEnrolledDependent = true;
            this.dependentId = "";
            this.optionsForDependent = false;
            this.confirmMemberPlan = false;
            this.dependentModal = false;
          }
        });
    },
    reviseInfo() {
      this.errorMsg = false;
      this.memberError = false;
      this.messages = false;
      this.dependentModal = true;
    },
    reviseMember() {
      this.memberErrorModal = false;
      // this.addMemberModal = true;
    },
    cancelNewMember() {
      this.addMemberModal = false;
    },
    getHomeInformation() {
      api()
        .get(`/member/app/home`)
        .then((res) => {
          if (res) {
            this.providerId = res.data.providerId;
            this.locationId = res.data.locationId;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getaccountInformation() {
      api()
        .get(`/member/app/account`)
        .then((res) => {
          if (res) {
            this.user = res.data.personal;
            this.role = res.data.subscriberRelation;
            this.newMember.companyName = this.user.companyName;
            this.newMember.planId = this.user.planID;
            this.newMember.payerId = this.user.payerID;
            this.newMember.groupId = this.user.groupID;
            //this.newMember.memberId = this.user.memberID;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    formatPhoneNumber(event) {
      this.newMember.phone = event;
      this.formattedPhoneNumber = this.formatAsPhoneNumber(
        this.newMember.phone
      );
    },
    confirmFormatPhoneNumber(event) {
      this.confirmMember.phone = event;
      this.confirmFormattedPhoneNumber = this.formatAsPhoneNumber(
        this.confirmMember.phone
      );
    },
    formatAsPhoneNumber(value) {
      value = value.replace(/\D/g, "");
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      value =
        value.slice(0, 3) + " " + value.slice(3, 6) + " " + value.slice(6);
      return value;
    },
    getPlans() {
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;
          }
        })
        .catch((err) => {
          if (err.response.data) {
            this.error = err.response.data;
          } else {
            this.error = "Something went wrong. Please try again later.";
          }
        });
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
  
  <style scoped lang="scss">
</style>
  