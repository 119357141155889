<template>
    <div style="padding:20px">    
        <div class="d-flex flex-column flex-md-row justify-space-between align-center blue-underline pb-3 pt-3">
        <div class="d-flex flex-column flex-md-row align-center" style="width:100%">
           <div class="mt-5 mt-md-0 ml-0 d-flex" role="button">
             <svg  @click="$router.go(-1)"  class="mr-2 mt-3 cursor-pointer"  width="14"  height="16"  viewBox="0 0 14 25"  fill="none" xmlns="http://www.w3.org/2000/svg"  >
             <path  d="M12.6269 23.764L1.99634 12.9562C1.92725 12.8861 1.87267 12.803 1.83571 12.7118C1.79875 12.6205 1.78014 12.5229 1.78095 12.4245C1.78176 12.326 1.80197 12.2287 1.84042 12.1381C1.87888 12.0474 1.93482 11.9653 2.00505 11.8963L12.8118 1.26475"   stroke="gray"
               stroke-width="2" stroke-linecap="round"  stroke-linejoin="round" />
           </svg>
           <p  @click="$router.go(-1)" class="font-weight-bold mr-10 text-gray mt-2 font-16 cursor-pointer">Back to Solutions</p>
             <v-text-field
             prepend-inner-icon="mdi-magnify"
             v-model="searchQuery"
             light  dense outlined hide-details
             placeholder="Search Solutions"
           ></v-text-field>
           </div>
        </div>
     </div>
        <v-card-title>
            <h3>{{category}} </h3>
          </v-card-title>
          <div class="row-height">
            <div v-if="getCategoryItems(category) == false" class="col-12"> 
              <v-alert border="bottom" colored-border color="primary" elevation="2" class="col-4 text-gray mt-2 ml-2">
                  No results found!
              </v-alert>
          </div>
            <div v-else class="col-12 row d-flex ma-1">                 
              <v-card  v-for="(item,index) in getCategoryItems(category)" :key="index" height="190px" width="220px" class=" mb-5 mt-1 mr-2 col-2 pa-0" >
              <div class="display pl-1 pr-1 pb-1"  @click="goToDetails(item, item.entity)">
                <div class="text-center justify-center mb-1 mt-0">
                  <img
                  v-if="item.entity == 'solution'"
                  :src="item.logo ? item.logo : '/tpa/virtualNetwork.svg'"
                  class="pointer mt-3 mb-0" width="120" height="60" />
                  <img
                  v-if="item.entity == 'location'"
                  :src="item.logo ? item.logo : '/tpa/clinicsAndOrganizations.svg'"
                  class="pointer mt-3 mb-0" width="120" height="60" />
                  <img
                  v-if="item.entity == 'vendor'"
                  :src="item.logo ? item.logo : '/tpa/pointSolutionVendor.svg'"
                  class="pointer mt-3 mb-0" width="120" height="60" />
                  <img
                  v-if="item.entity == 'provider'"
                  :src="item.logo ? item.logo : '/tpa/clinicians.svg'"
                  class="pointer mt-3 mb-0" width="120" height="60" />
                </div>
                <span class="text-center mt-0 font-weight-bold">
                  <p class="font-14 mb-0" v-if="item.name.length > 21">
                 {{item.name.substring(0,20)+ '...'}}
                  </p>
                  <p class="font-14 mb-0" v-else>
                    {{item.name}} 
                     </p>
                </span>
                <span class="text-center">
                  <p class="font-14 mb-0" v-if="item.address?.length > 20">
                    {{item.address.substring(0,19)+ '...'}}
                     </p>
                     <p class="font-14 mb-0" v-else>
                       {{item.address ? item.address : 'Address'}}
                        </p>
                </span>
                <div class="justify-center d-flex">
                  <v-chip
                          v-if="item.entity == 'solution'"
                          class="ma-2 pa-2" color="#AAC9F2" text-color="#333333">
                        Virtual Network
                        </v-chip>
                        <v-chip
                        v-if="item.entity == 'vendor'"
                        class="ma-2 pa-2" color="#FFE2CC"  text-color="#333333">
                      Point Solution Vendor
                      </v-chip>
                      <v-chip
                      v-if="item.entity == 'location'"
                        class="ma-2 pa-2" color="#FFF4BF" text-color="#333333">
                      Clinic & Organization
                      </v-chip>
                      <v-chip
                      v-if="item.entity == 'provider'"
                        class="ma-2 pa-2" color="#CDFFCC"  text-color="#333333">
                      Clinician
                      </v-chip>
                </div>
              </div>
            </v-card> 
           </div>
          </div>
    </div>
  </template>
  <script>
  import api from "../../../../services/api";
  export default {
    data() {
      return {
        marketplace: [],
        searchQuery: "",
        model: null,
        items:[],
        filteredItems:[],
        showOverlay: false,
        category: "",
      };
    },
    watch: {
    },
    computed: {
      isChildren() {
        let bool = false;
        if (this.$route.meta && this.$route.meta.isChildren) {
          bool = true;
        }
        return bool;
      },
    },
    created() {
      this.getMarketplace();
    this.category = this.$route.params.category;
    },
    methods: {
      getCategoryItems(category) {
        const categoryData = this.marketplace.find(
          (item) => item.category === category
        );
        if (categoryData) {
          if (this.searchQuery) {
            const query = this.searchQuery.toLowerCase();
            return categoryData.items.filter((item) => {
              const nameMatch = item.name.toLowerCase().includes(query);
              const addressMatch = item.address.toLowerCase().includes(query);
              const typeMatch = item.type.toLowerCase().includes(query);
              return nameMatch || addressMatch || typeMatch;
            });
          } else {
            return categoryData.items;
          }
        } else {
          return [];
        }
      },
      getMarketplace() {
        api()
          .get("/member/app/market")
          .then((res) => {
            this.marketplace = res.data;
          });
      },
      goToDetails(item, entity) {
        if (entity == "solution") {
          this.$router.push("/marketplace/details/" + item.id);
        } else if (entity == "location") {
          this.$router.push("/marketplace/provider/details/" + item.id);
        } else if (entity == "vendor") {
          this.$router.push("/marketplace/vendor/details/" + item.id);
        } else if (entity == "provider") {
          this.$router.push("/marketplace/clinician/details/" + item.id);
        }
  
        this.showOverlay = false;
      },
      closeErrorModal() {
        this.$refs.error_modal.closePopUp();
      },
      closeSuccessModal() {
        this.$refs.success_modal.closePopUp();
      },
      confirmRemove() {},
     
    },
  };
  </script>
  