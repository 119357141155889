<template>
  <div class="page-background-alert">
    <!-- <img @click="$router.push({name:'Home'})" class="apaly-logo" src="/apaly.png" alt="" /> -->

    <div class="row alert-text" v-if="type == 'add'">
      Success!
      <br />
      You’ve added
      <br />
      a family member.
      <br>
      <small class="small-text" @click="$router.go(-1)">
        <svg class="mr-2" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1.70711 8.29289C1.31658 8.68342 1.31658 9.31658 1.70711 9.70711L9 17" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Go back
      </small>
    </div>

    <div class="row alert-text" v-if="type == 'update'">
      Success!
      <br />
      You’ve updated
      <br />
      the insurance information.
      <br>
      <small class="small-text" @click="$router.go(-1)">
        <svg class="mr-2" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1.70711 8.29289C1.31658 8.68342 1.31658 9.31658 1.70711 9.70711L9 17" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Go back
      </small>
    </div>

    <div class="row alert-text" style="width: 199px"  v-if="type == 'verification'">
      <span style="font-size: 30px">Check your email</span>
      <br />
      There should be
      <br />
      a verification link.
      <br>
      <small class="small-text" @click="$router.go(-1)">
        <svg class="mr-2" width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L1.70711 8.29289C1.31658 8.68342 1.31658 9.31658 1.70711 9.70711L9 17" stroke="white"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Go back
      </small>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      type: this.$route.params.type,
    };
  },
  components: {},
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss">
.page-background-alert {
  height: 100vh;
  background-color: #16407a;
}

.apaly-logo {
  position: absolute;
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
}

.alert-text {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}
.small-text{
  margin-top:30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
