<template>
  <div class="page-background">
    <main-header :title="'Login'" :color="'white'" ></main-header>
    <div class="page-background-alert">
      <div class="d-flex flex-column align-center justify-space-between pr-5 pl-5 " >
        <!-- <div style="margin-top: 100px;margin-bottom: 100px !important;">
          <img class="apaly-logo" src="/apaly.png" alt="">
        </div> -->
        <div class="d-flex flex-column align-center" style="width: 100%" >
          <form style="width: 100%">
            <div>
              <h3 class="form-title">Confirm Email</h3>
              <v-text-field dark outlined label="Email (login)" type="email" class="input-text" v-model="email" @blur="lowerCaseEmail()" :rules="emailRules"></v-text-field>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="d-flex footer"  style="">
      <div class="col-6">
        <v-btn class="footer-btn-cancel" >Cancel</v-btn>
      </div>
      <div class="col-6">
        <v-btn class="footer-btn" @click="resetPassword" >Reset Password</v-btn>
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000">
      {{textInSnackbar}}
    </v-snackbar>
  </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import {mapGetters} from "vuex";
import api from "@/services/api";

export default {
  data() {
    return {
      show:false,
      snackbar:false,
      textInSnackbar:'',
      email:'',
      emailRules: [
        (v) => !!v || "This Field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
    };
  },
  computed:{
    ...mapGetters(["getEmail"]),
  },
  components: {
    mainHeader,
  },
  methods: {
    lowerCaseEmail(){
      this.email = this.email.toLowerCase()
    },
    resetPassword(){
      api().get(`/member/app/reset/password?email=${this.email}`).then((res)=>{
        if(res){
          console.log('resetPassword res',res);
          this.snackbar = true
          this.textInSnackbar=res.data;
        }
      })
    }
  },
  created() {
    this.email = this.$store.getters.getEmail;
  },
};
</script>
<style scoped lang="scss">

.page-background {

  background-color: #001e46;
}

.page-background-alert {
  height: 100vh;
  background-color:  #16407A;
}

.apaly-logo{
  width: 162.17px;
  height: 65px;
  left: 115px;
  top: 186px;
;
}

.form-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
  color: white;
}

.alert-text{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  color: white;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
  text-align: center;
  position: absolute;
  width: 184px;
  height: 80px;
  left: 105px;
  top: 386px;
}

.footer{
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}

.footer-btn-cancel{
  background-color:#16407a !important;
  border-radius: 23px;
  width: 100%;
  border: 1px solid white !important;
  color: white;
  float:right
}
.footer-btn{
  background-color: white !important;
  border-radius: 23px;
  width: 100%;
  color: #16407A ;
}
.entry-title{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.entry-description{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.above-footer-text{
  margin-top: 170px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin-left: 17px;
  color: #C9C9C9;

}
</style>