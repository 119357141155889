import Vue from "vue";
import VueRouter from "vue-router";

import DPCMmember from './dpc-member.js'
import store from '../store/index.js';
Vue.use(VueRouter);


// let routeArray = Login.concat(DPCMmember,ProviderAdvisor)

const routes = DPCMmember
const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach((to, from, next) => {
  let pageTitle = to.name; // Extract page title from route parameters
  if(store.state.documentTitle != null){
    pageTitle = store.state.documentTitle
  }
  document.title = `Member - ${pageTitle}`
  // console.log('documentTitle', store.state.documentTitle)
  // if(!store.state.documentTitle){
  //   document.title = pageTitle ? `Apaly Member - ${pageTitle}` : 'Apaly Member';
  // }else{    
  //   console.log('else')
  //   store.state.documentTitle = document.title
  //   document.title = store.state.documentTitle
  // }
  //store.state.documentTitle = document.title
  //store.getters.getTitle = document.title;
  // store.commit("setTitle", document.title);
  next();
});
router.afterEach((to, from) => {
  let pageTitle = to.name; // Extract page title from route parameters
  // console.log('store', store.state.documentTitle)
  if(store.state.documentTitle != null){
    pageTitle = store.state.documentTitle
  }
  document.title = `Member - ${pageTitle}`
 

});

export default router;
