<template>
  <div class="d-flex justify-space-between align-center mobile-padding" :style="'background-color:'+ bgColor">
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$router.go(-1)">
      <path d="M9 1L1.70711 8.29289C1.31658 8.68342 1.31658 9.31658 1.70711 9.70711L9 17" :stroke="color"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <div  :style="'color:'+color" class="navy-header"> {{ title }} </div>
    <div>
      <v-icon color="#16407A" style="color:rgb(22, 64, 122) !important;" v-text="'mdi-plus-circle-outline'" v-if="icon == 'add'"  @click="addMemberFunc"></v-icon>

      <svg @click="editFunc" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon == 'edit'">
        <path d="M16.9734 1.05769C17.364 0.667168 17.9971 0.667168 18.3876 1.05769L22.6303 5.30033C23.0208 5.69086 23.0208 6.32402 22.6303 6.71455L7.28268 22.0622C7.12072 22.2241 6.90806 22.3256 6.68026 22.3496L1.93848 22.8487C1.30588 22.9153 0.772706 22.3821 0.839295 21.7495L1.33843 17.0077C1.36241 16.7799 1.46386 16.5673 1.62583 16.4053L16.9734 1.05769Z" stroke="#16407A" stroke-width="1.5" />
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "color", "icon",'bgColor'],
  components: {},
  data() {
    return {};
  },
  created() { },
  computed: {
    
  },
  methods: {
    editFunc() {
      this.$emit("editFromHeader");
    },
    addMemberFunc() {
      this.$emit("addMemberFromHeader");
    },
  },
};
</script>
<style scoped lang="scss"></style>
