import api from "@/services/api"

const state ={
    filters:{},
    email:null,
    registrationData: null,
    employerPlans: []
}

const getters = {
  getFilters(state){
    return state.filters
  },
  getRegistrationData(state){
    return  state.registrationData
},
}

const mutations = {
    setFilters(state,data){
        state.filters[data.title] = data.value
    },
    setEmail(state,email){
        state.email = email
    },
    setRegistrationData(state,registrationData){
        state.registrationData = registrationData
    },
    setEmployerPlans(state, plans){ state.employerPlans = plans }
}

const actions = {
    async getEmployerPlans(context){
        var res = await api().get(`/member/app/plans`)
        context.commit('setEmployerPlans', res.data);
        console.log('getEmployerPlans',res.data)
    },

}

export default{
    state,
    getters,
    mutations,
    actions
}