<template>
    <div>
      <main-header :title="'Find Care'" :color="'white'" bg-color="#001E46"></main-header>
      <v-select dark class="searchBar" :items="members" :item-text="(i) =>i .firstName+' '+i.lastName" item-value="_id" v-model="member">
        <template v-slot:item="slotItem">
          <div class="d-flex justify-space-between info-box-list-select width-100">
            <div>
              {{ slotItem.item.firstName +' '+ slotItem.item.lastName }}
            </div>
            <div>
              {{ slotItem.item.provider ? slotItem.item.provider.companyName : 'Needs a provider' }}
            </div>
          </div>
        </template>
      </v-select>

      <div class="d-flex flex-column filters">
        <div class="navy-title">
          Selected Filters:
        </div>
        <div class="d-flex justify-space-between">
          <div>

          </div>
          <div>
            <v-btn @click="$router.push({name:'FindCareSearchFilters',params:{'member':member}})" elevation="1" outlined rounded text class="width-100 blue-background text--white">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 15V1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4 21C5.65685 21 7 19.6569 7 18C7 16.3431 5.65685 15 4 15C2.34315 15 1 16.3431 1 18C1 19.6569 2.34315 21 4 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 23L12 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 17V1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M20 23C21.6569 23 23 21.6569 23 20C23 18.3431 21.6569 17 20 17C18.3431 17 17 18.3431 17 20C17 21.6569 18.3431 23 20 23Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mobile-content-background scrollable">
        <v-radio-group class="mobile-content" v-model="selectedCare">
          <div class="d-flex flex-column provider-list" v-for="(item,key) in providers" :key="key">
            <div class="d-flex justify-space-between">
              <div class="blue-header">
                {{ item.title }}
              </div>
              <div>1.2m</div>
            </div>
            <div class="d-flex justify-space-between align-center">
              <div>
                Main Street West <br>
                1313 Main Street West <br>
                Tampa, FL 33602 <br>
                <span class="blue-title">
                  p: 813-555-1212
                </span>
              </div>
              <div>
                <v-radio :value="item" @click="changeProviderDialog = true"></v-radio>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column p-5">
            <div class="info-box-list-single text-center">
              <div class="gray-title">End of Provider List</div>
            </div>
          </div>
        </v-radio-group>
      </div>

      <div class="footer">
        <div class="d-flex justify-space-between">
          <v-btn @click="$router.push({name:'FindCare'})" elevation="1" outlined rounded x-large text class="white-background blue--text mr-2" style="border-color: #16407A;width:50%;">
            Cancel
          </v-btn>
          <v-btn elevation="1" outlined rounded x-large text class="blue-background white--text" style="width:50%;">
            Save
          </v-btn>
        </div>
      </div>

      <v-dialog v-model="changeProviderDialog" width="500">
        <v-card>
          <v-card-title class="dialog-title">
            Are you sure you want to change providers?
          </v-card-title>

          <v-card-text class="text-center">
            <p class="blue-text">
              You can only change solutions once per month. <br>
              Beverly Smith is currently enrolled with ABC Health. Are you sure you want to switch this family member to Everside Health?
            </p>
          </v-card-text>

          <div></div>
          <v-card-actions>
            <div class="d-flex justify-space-between width-100">
              <v-btn @click="() => {changeProviderDialog = false; selectedCare = member.provider}" elevation="1" outlined rounded x-large text class="white-background blue--text mr-1" style="border-color: #16407A;width:50%;">
                Cancel
              </v-btn>
              <v-btn @click="switchProvider()" elevation="1" outlined rounded x-large text class="blue-background white--text" style="width:50%;">
                Switch
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="changeProviderForFamilyMembersDialog" v-if="selectedCare" width="500">
        <v-card>
          <v-card-title class="dialog-title" style="border-bottom:1px solid lightgray;">
            <div class="d-flex justify-space-between width-100">
              <div class="navy-title">{{ selectedCare.title }}</div>
              <div>
                <svg @click="changeProviderForFamilyMembersDialog = false" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L15 15" stroke="#16407A" stroke-width="2" stroke-linecap="round"/>
                  <path d="M1 15L15 1" stroke="#16407A" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          </v-card-title>

          <v-card-text class="text-center">
            <v-checkbox
                @click="selectAll('selectedCareForFamilyMembers',members.length)"
                label="Select All"
                :value="selectedCareForFamilyMembers.length >= members.length"
            ></v-checkbox>
            <v-checkbox
                v-for="(member,index) in members" :key="index"
                v-model="selectedCareForFamilyMembers"
                :label="member.firstName+' '+member.lastName"
                :value="member.id"
            ></v-checkbox>
          </v-card-text>

          <div></div>
          <v-card-actions>
            <div class="d-flex justify-space-between width-100">
              <v-btn @click="switchProviderForFamilyMembers()" elevation="1" outlined rounded x-large text class="blue-background white--text width-100">
                Save
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";
export default {
  data() {
    return {
      changeProviderForFamilyMembersDialog:false,
      changeProviderDialog:false,
      selectedCare:null,
      selectedCareForFamilyMembers:[],
      member:{},
      members:[],
      providers:[
        {
          _id:'z2b1uhb2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1uhb21212b1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1u131332u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2asfgasb2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2basfu1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1123b2u1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1asffgu1hb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        },
        {
          _id:'z2b1aasuhfshb2ub1',
          title:'Everside Health',
          desc:'Main Street West 1313 Main Street West Tampa, FL 33602',
          p:'p: 813-555-1212',
        }
      ]
    };
  },
  components:{
    mainHeader
  },
  methods: {
    selectAll(arr,len){
      if(this[arr].length >= len){
        this[arr] = []
      }else{
        this[arr] = this.members.map(i => {
          return i.id
        });
      }
    },
    switchProvider(){
      console.log(this.selectedCare)
      this.changeProviderDialog = false
      this.changeProviderForFamilyMembersDialog = true
    },
    switchProviderForFamilyMembers(){
      console.log(this.selectedCareForFamilyMembers)
    }
  },
  created() {
    api().get("/member/app/family/members")
        .then((res) => {
          this.members = res.data
          this.member = res.data[0]
        });
  },
};
</script>
<style scoped lang="scss">


.searchBar{
  background-color: #16407A;
  padding:5px 25px 5px 25px;
}
.mobile-content-background{
  background-color:#FFF;
  height:100vh;
}
.mobile-content{
  padding:16px 24px 0 24px;
}
.info-box{
  background-color: white;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
}
.info-box-list-single{
  margin: 18px;
}
.info-box-list-select{
  margin: 12px;
  border-bottom: 1px solid #C9C9C9;
}
.info-box-list-select > div{
  padding: 10px 0;
}
.filters{
  padding:5px 25px 5px 25px;
  background-color:#FFF;
  border-bottom:1px solid #C9C9C9;
}
.provider-list{
  padding: 12px 0;
  border-bottom: 1px solid #C9C9C9;
}
.footer{
  background-color: white;
  border-top:1px solid #C9C9C9;
  padding:15px 15px 0 15px;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer > button {
  background-color: #16407A;
  color:white;
}
.scrollable{
  height: 70vh;
}
</style>