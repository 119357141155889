<template>
    <div class="text-center">
      <v-dialog  v-model.trim="someThingWentWrong" width="500">
        <v-card >
          <v-card-text class=" pa-3">
            <div class="dialog_title dialog_error col-12">
              {{ errorMess != '' ? errorMess : 'Oops, something went wrong. Please try again.' }}
            </div>
            <div class="col-12 text-center">
               <v-btn
              class="text-white"
              color="#0069F3"
              width="170"
              style="border-radius: 8px;"
              @click="closePopUp()"
            >
              Retry
          </v-btn>  
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        // someThingWentWrong: false,
        error: "",
      };
    },
    props: {
      someThingWentWrong: {
        type: Boolean,
      },
      errorMess: {
        type: String,
      }
    },
    mounted() {
      this.error = this.errorMess;
    },
    methods: {
      closePopUp() {
        this.someThingWentWrong = false;
      },
      openPopUp() {
        this.someThingWentWrong = true;
      },
    },
  };
  </script>
  
  <style>
  </style>