<template>
    <div>
      <main-header :title="member.firstName+' '+member.lastName+'’s Filters'" :color="'white'" bg-color="#001E46"></main-header>

      <div class="d-flex flex-column filters mb-2">
        <div class="white-title">
          Selected Filters:
        </div>
        <div class="d-flex horizontal-scroll">
          <div class="mr-2">
            <v-btn elevation="1" outlined rounded large text class="white-background blue--text">
              Clear all
              <i class="blue-background white--text ml-2" style="border-radius: 50%;padding: 5px 7px;">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 1.5L8.5 8.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M1.5 8.5L8.5 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </i>
            </v-btn>
          </div>
          <div class="mr-2">
            <v-btn elevation="1" outlined rounded large text class="blue-background white--text" style="border: 1px solid white">
              20 miles
              <i class="white-background blue--text ml-2" style="border-radius: 50%;padding: 5px 7px;">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 1.5L8.5 8.5" stroke="#16407A" stroke-width="1.5" stroke-linecap="round"/>
                  <path d="M1.5 8.5L8.5 1.5" stroke="#16407A" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
              </i>
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mobile-content-background scrollable">
        <div class="d-flex flex-column">
          <div class="white-title mb-2">Distance</div>
          <div class="d-flex align-baseline white--text">
            <v-text-field
                dark
                type="number"
                placeholder="20 miles"
                outlined
                v-model="distance"
                label="Distance (miles)"
            ></v-text-field>
            <span class="ma-2">From</span>
            <v-text-field
                dark
                type="number"
                outlined
                placeholder="00000"
                label="Zip Code"
            ></v-text-field>
          </div>
          <v-slider dark v-model="distance" max="30"></v-slider>
          <hr>
          <div class="white-title mt-2 mb-2">Search Provider Name</div>
          <div class="d-flex align-baseline white--text">
            <v-autocomplete
                dark
                type="text"
                :items="['user1','user2','user3','user4']"
                placeholder="Search"
                outlined
                label="Provider Name (First or Last)"
            ></v-autocomplete>
          </div>
          <hr>
          <div class="white-title mt-2 mb-2">Credentials</div>
          <div class="d-flex justify-space-between align-baseline white--text">
            <v-checkbox
                dark
                color="white"
                @click="selectAll('credentials',3)"
                label="Select All"
                :value="credentials.length >= 3"
            ></v-checkbox>
            <v-checkbox
                dark
                color="white"
                v-model="credentials"
                label="Physician Assistant"
                value="Physician Assistant"
            ></v-checkbox>
          </div>
          <div class="d-flex justify-space-between align-baseline white--text">
            <v-checkbox
                dark
                color="white"
                v-model="credentials"
                label="Doctor"
                value="Doctor"
            ></v-checkbox>
            <v-checkbox
                dark
                color="white"
                v-model="credentials"
                label="Nurse Practitioner"
                value="Nurse Practitioner"
            ></v-checkbox>
          </div>
          <hr>
          <div class="white-title mt-2 mb-2">Provider Gender</div>
          <div class="d-flex align-baseline white--text">
            <v-checkbox
                dark
                class="mr-4"
                color="white"
                v-model="gender"
                label="Female"
                value="Female"
            ></v-checkbox>
            <v-checkbox
                dark
                color="white"
                v-model="gender"
                label="Male"
                value="Male"
            ></v-checkbox>
          </div>
          <hr>
          <div class="white-title mt-2 mb-2">Organization</div>
          <div class="d-flex align-baseline white--text">
            <v-autocomplete
                dark
                type="text"
                :items="['user1','user2','user3','user4']"
                placeholder="Search"
                outlined
                label="Provider Name (First or Last)"
            ></v-autocomplete>
          </div>
          <v-checkbox
              dark
              color="white"
              @click="selectAll('organizations',2)"
              label="Select All"
              :value="organizations.length >= 2"
          ></v-checkbox>
          <v-checkbox
              dark
              color="white"
              v-model="organizations"
              label="Eden Health"
              value="Eden Health"
          ></v-checkbox>
          <v-checkbox
              dark
              color="white"
              v-model="organizations"
              label="Everside Health"
              value="Everside Health"
          ></v-checkbox>
        </div>
      </div>

      <div class="footer">
        <div class="d-flex justify-space-between">
          <v-btn @click="$router.go(-1)" elevation="1" outlined rounded x-large text class="blue-background white--text mr-2" style="border-color: #fff;width:50%;">
            Cancel
          </v-btn>
          <v-btn elevation="1" outlined rounded x-large text class="white-background navy-title" style="width:50%;">
            Save
          </v-btn>
        </div>
      </div>

    </div>
</template>

<script>
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
export default {
  data() {
    return {
      member: {},
      selectedCare:null,
      distance:20,
      credentials:[],
      organizations:[],
      gender:[],
      providers:[
          {
            _id:'z2b1123123ub1',
            client:'William2 Smith',
            provider:'Everside Health'
          },
          {
            _id:'z2b1uhb2u1hb2ub1',
            client:'William Smith',
            provider:'Everside Health'
          },
      ],
    };
  },
  components:{
    mainHeader
  },
  methods: {
    selectAll(arr,len){
      if(this[arr].length >= len){
        this[arr] = []
      }else{
        this[arr] = (arr == 'credentials') ? [
          "Physician Assistant",
          "Doctor",
          "Nurse Practitioner",
        ] : [
          "Eden Health",
          "Everside Health",
        ];
      }
    }
  },
  created() {
    if(this.$route.params.member){
      this.member = this.$route.params.member
    }else{
      this.$router.push({name:'FindCare'})
    }
  },
};
</script>
<style scoped lang="scss">


.searchBar{
  background-color: #16407A;
  padding:5px 25px 5px 25px;
}
.mobile-content-background{
  background-color:transparent;
  padding: 15px;
  height:100vh;
}
.mobile-content{
  padding:16px 24px 0 24px;
}
.info-box{
  background-color: white;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
}
.info-box-list-single{
  margin: 18px;
}
.info-box-list-select{
  margin: 12px;
  border-bottom: 1px solid #C9C9C9;
}
.info-box-list-select > div{
  padding: 10px 0;
}
.filters{
  padding:5px 25px 5px 25px;
  border-bottom:1px solid #C9C9C9;
  color: white;
}
.provider-list{
  padding: 12px 0;
  border-bottom: 1px solid #C9C9C9;
}
.footer{
  background-color: #16407A;
  border-top:1px solid #C9C9C9;
  padding:15px 15px 0 15px;
  height:80px;
  position:absolute;
  bottom:0;
  width:100%;
  align-items: flex-start;
  border-top: 1px solid #C9C9C9
}
.footer > button {
  background-color: #16407A;
  color:white;
}
.scrollable{
  height: 70vh;
}
.horizontal-scroll{
  overflow-x: scroll;
}
</style>